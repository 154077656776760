<!-- Footer -->
<div class="footer-area pt-100 pb-70" style="padding-top: 55px;    padding-bottom: 0%;" >
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <div   class="logo">
                        <a    routerLink='/'> 
                            <img  src="assets/img/215.webp" alt="image" />
                        </a>
                    </div>
                     <ul class="social">
                        <li><a href="https://www.facebook.com/Catholic.Christian.Education.Egypt" class="facebook" title="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UC10YpLTFWl0V1Wd82JEHHiA" class="youtube" title="youtube" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                     </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6"  >
                <div class="single-footer-widget pl-5">
                    <h3>روابط سريعة  </h3>

                    <ul class="quick-links"> 
                        <li><a routerLink="News/List/1">أخبار وأنشطة</a></li>
                        <li><a routerLink="Articals/List/2">  مقالات </a></li>
                        <li><a routerLink="SpirtualEvents/List">رياضات روحية </a></li>
                        <li><a routerLink="RelatedSites/SitesList">مواقع صديقة</a></li>
                        <li><a routerLink="Branches/ContactUs">تواصل معانا</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6" >
                <div class="single-footer-widget pl-5">
                    <h3>خدماتنا  </h3>

                    <ul class="quick-links">
                        <li><a routerLink="Curriculas/ServantCurriculasList/1">  مناهج الخدام</a></li>
                        <li><a routerLink="Curriculas/CurriculasList/0">مناهج المخدومين</a></li> 
                        <li><a routerLink="Lessons/LessonsList">لقاءات</a></li>
                        <li><a routerLink="EducationalMethods/MethodsList">وسائل إيضاح</a></li>
                        <li><a routerLink="LibraryFiles/BooksList">كتب </a></li> 
                    </ul>
                </div>
            </div>

            

            <div class="col-lg-3 col-sm-6" >
                <div class="single-footer-widget pl-5">
                    <h3>تواصل معنا</h3>

                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-call"></i>
                            <span>الهاتف</span>
                            <a href="tel:01061829720">{{tele}}</a>
                        </li>
                        <li>
                            <i class="flaticon-email-1"></i>
                            <span>البريد الإلكتروني</span>
                            <a href="mailto:egyptian.episcopal.commission@gmail.com">egyptian.episcopal.commission@gmail.com</a>
                        </li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Footer -->

<!-- Copyright -->
<div class="copyright-area footer-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class="far fa-copyright"></i>     اللجنة الأسقفية للتعليم المسيحي الكاثوليكي - مصر   
                        </p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                         <li><a href="https://www.linkedin.com/in/kerolouse-francies-89198196/" target="_blank">Kerolouse francies</a></li>
                  
                        <li style="color: #f4f2c9;font-size: 20px;">Developed by</li>
                         </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Copyright -->

<!-- Go Top -->
<div class="go-top">
    <i class='bx bx-chevron-up'></i>
</div>
<!-- End Go Top -->