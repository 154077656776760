import { BrowserModule } from '@angular/platform-browser'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';   
import { HttpClientModule } from '@angular/common/http';      
import { NgModule } from '@angular/core'; 
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
 import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component'; 
import { SharedInAllComponentsModule } from './Moduels/shared-in-all-components/shared-in-all-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 

 @NgModule({
  declarations: [
    AppComponent,
    MainComponent, 
    NavbarComponent,
    FooterComponent,
    ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserModule,
    BrowserAnimationsModule, 
    AppRoutingModule, 
    HttpClientModule,
   ServiceWorkerModule.register('ngsw-worker.js', {
    enabled:environment.production,
     // Register the ServiceWorker as soon as the application is stable
     // or after 30 seconds (whichever comes first).
    //  registrationStrategy: 'registerWhenStable:30000'
     registrationStrategy: 'registerImmediately'
   }),
   SharedInAllComponentsModule, 
    
  ],
  providers: [  
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
   
  
  
 }
