import { Component, OnInit } from '@angular/core'; 
import {  Router } from '@angular/router'; 
import { CrudServiceService } from 'src/app/Services/common/crud-service.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  
  constructor(  private crudService:CrudServiceService, private router:Router ) { }
    searchResult:any;
    flag=false;
    isControlPanel=false; 
  ngOnInit(): void {
 

  }
  
     searchOnKeyUp(event) {
      let input = event.target.value; 
      this.flag = false;
      if (input.length > 3) {
     
        this.crudService.getAll('Search/GetGlobalSearch?searchTerm='+input).subscribe(res=>{
          this.searchResult=res;
            if(this.searchResult.length>0)
            {
              this.flag = true;
            }
         });

      }
    } 

     
    search(term:string)
    { 
      // this.router.navigateByUrl("Search/"+term);
     
    }

}
