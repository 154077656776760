import { Component, OnInit } from '@angular/core';
 import { ArabicNumberPipePipe } from 'src/app/pipe/arabic-number-pipe.pipe';
 
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  address:string;
  tele:string;
  constructor(
     private ArabicNumberPipe:ArabicNumberPipePipe
    ) {
     this.tele=  ArabicNumberPipe.transform( "01061829720");
   }
 
  ngOnInit(): void {
  }

}
