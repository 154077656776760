import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
import { ErrorComponent } from './components/common/error/error.component';   
import { MainComponent } from './main/main.component';
const routes: Routes = [
  {path:'',children: [
  
  { path: '', loadChildren: () => import('./Moduels/home/home.module').then(m => m.HomeModule) },
  { path: 'MainPage', loadChildren: () => import('./Moduels/news-and-articals/news-and-articals.module').then(m => m.NewsAndArticalsModule) },
  { path: 'News', loadChildren: () => import('./Moduels/news-and-articals/news-and-articals.module').then(m => m.NewsAndArticalsModule) },
  { path: 'Articals', loadChildren: () => import('./Moduels/news-and-articals/news-and-articals.module').then(m => m.NewsAndArticalsModule) },
   { path: 'SpirtualEvents', loadChildren: () => import('./Moduels/events-and-curriculas/events-and-curriculas.module').then(m => m.EventsAndCurriculasModule) },
   { path: 'Curriculas', loadChildren: () => import('./Moduels/events-and-curriculas/events-and-curriculas.module').then(m => m.EventsAndCurriculasModule) },
  { path: 'LibraryFiles', loadChildren: () => import('./Moduels/others-components/others-components.module').then(m => m.OthersComponentsModule) },
  { path: 'RelatedSites', loadChildren: () => import('./Moduels/others-components/others-components.module').then(m => m.OthersComponentsModule) },
  { path: 'Lessons', loadChildren: () => import('./Moduels/others-components/others-components.module').then(m => m.OthersComponentsModule) },
  { path: 'EducationalMethods', loadChildren: () => import('./Moduels/others-components/others-components.module').then(m => m.OthersComponentsModule) },
  { path: 'Branches', loadChildren: () => import('./Moduels/others-components/others-components.module').then(m => m.OthersComponentsModule) },
  { path: 'Search', loadChildren: () => import('./Moduels/search/search.module').then(m => m.SearchModule) },
  

  
  
],component:MainComponent},
 
  {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list

];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled', relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
