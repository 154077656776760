import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrudServiceService {
 url:string=environment.baseUrl+'/api/';
  constructor(private http:HttpClient) { }

  getAll(componentUrl)
  {
    return this.http.get(this.url+componentUrl);
  }
  getAllForPaging(componentUrl,object)
  {
    return this.http.post(this.url+componentUrl, object);
  }
  getById(componentUrl,id)
  {
    return  this.http.get(this.url+componentUrl+'/'+id);
    // 'https://jsonplaceholder.typicode.com/todos/1'
  }
  save(componentUrl,object)
  { 
    return this.http.post(this.url+componentUrl,object);
  }
  edit(componentUrl,object)
  {
    return this.http.put(this.url+componentUrl,object);
  }
  delete(componentUrl,id)
  {
    return this.http.delete(this.url+componentUrl+'/'+id);
  }
}
