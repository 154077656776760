<!-- Navbar -->

<div class="navbar-area fixed-top">
    <div class="main-responsive-nav">
        <div class="container">
            <div class="main-responsive-menu"  >
                <div class="logo" style=" 
                right: 8px;left: auto;text-align: center;text-indent: 0px;font-size: 18px;position: fixed;top:3px;
                ">
                  <a routerLink="/" style=" right: 8px;left: auto;top:3px;">
                    <img src="/assets/img/35.webp"   alt="image">  
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="main-navbar" style="background-color: rgb(208 184 184 / 20%);">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                                <a class="navbar-brand" routerLink='/'><img src="/assets/img/90.webp" style="position: fixed;top:4px;" alt="image" ></a>
                                
                <div class="collapse navbar-collapse mean-menu" style="margin-right: 7%;" id="navbarSupportedContent">
                    <ul class="navbar-nav"    >
                         <li class="nav-item"><a routerLink="MainPage" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">  اللجنة الأسقفية للتعليم المسيحي  </a></li>
                        <li class="nav-item"><a routerLink="News/List/1" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">الأخبار والأنشطة</a></li>
                        <li class="nav-item"><a routerLink="Articals/List/2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">المقالات </a></li>
                        <li class="nav-item">
                            <a href="javascript:void(0);LibraryFiles/BooksList" class="nav-link"> المكتبة <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu"  style="backdrop-filter: saturate(180%) blur(20px);
                            ">
                                <li class="nav-item"><a routerLink="LibraryFiles/BooksList" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">كتب  </a></li>
                                <li class="nav-item"><a routerLink="LibraryFiles/ResearchesList" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> أبحاث  </a></li>
                                <li class="nav-item"><a routerLink="LibraryFiles/SummariesList" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> ملخصات  </a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="SpirtualEvents/List" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">الرياضات الروحية </a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0);Curriculas/CurriculasList/0" class="nav-link"> المناهج <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu" style="backdrop-filter: saturate(180%) blur(20px);">
                                <li class="nav-item"><a routerLink="Curriculas/CurriculasList/0" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">مناهج المخدومين</a></li>
                                <li class="nav-item"><a routerLink="Curriculas/ServantCurriculasList/1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> مناهج الخدام</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="Lessons/LessonsList" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">اللقاءات </a></li>
                        <li class="nav-item"><a routerLink="EducationalMethods/MethodsList" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">وسائل الإيضاح </a></li>
                        <li class="nav-item"><a routerLink="RelatedSites/SitesList" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">مواقع صديقة   </a></li>
                        <li class="nav-item"><a routerLink="Branches/ContactUs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">تواصل معنا     </a></li>
                    </ul>
                           

                    <div _ngcontent-ybe-c46="" class="others-options d-flex align-items-center">
                      
                        <div _ngcontent-ybe-c46="" class="option-item">
                            <i _ngcontent-ybe-c46="" class="search-btn flaticon-loupe"></i>
                            <i _ngcontent-ybe-c46="" class="close-btn flaticon-cancel"></i>
                            <div _ngcontent-ybe-c46="" class="search-overlay search-popup">
                                <div _ngcontent-ybe-c46="" class="search-box">
                                    <!-- (submit)='search(searchTerm.value)' -->
                                    <form _ngcontent-ybe-c46="" autocomplete="off"  class="search-form">
                                        <input _ngcontent-ybe-c46="" autocomplete="off" #searchTerm name="search" placeholder="بحث" type="text" (keyup)="searchOnKeyUp($event)"  class="search-input">
                                        <button _ngcontent-ybe-c46="" type="button" routerLink="Search/{{searchTerm.value!=''?searchTerm.value:'  '}}" class="search-button">
                                           <i _ngcontent-ybe-c46="" class="flaticon-loupe">   
                                            </i> 
                                        </button> 
                                    
                                        <div  *ngIf="flag">   
                                            <ul class="list-group list-group-flush">  
                                                <li *ngFor="let sr of searchResult" class="list-group-item">  
                                                    <a [href]="sr.pageUrl" target="_blank">{{sr.title}}</a>  
                                                </li>             
                                            </ul> 
                                            <div style="text-align: center;padding-top: 5%;">
                                            <a style="cursor: pointer;"  routerLink="Search/{{searchTerm.value}}">عرض الكل</a>  
                                            </div>
                                        </div> 

                                    </form>
                                </div>
                            </div>
                        </div>
                </div>




                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive" >
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                        <i class="search-btn flaticon-loupe cc" ></i>
                            <i class="close-btn flaticon-cancel cc" ></i>
                            </div>
            </div>   
             
                        <div class="search-overlay search-popup" style="margin-top: 5%;display: none;right: auto;">
                            <div class='search-box'>
                                <!-- (submit)='search(searchTermMob.value)' -->
                                <form class="search-form"  autocomplete="off">

                                    <input class="search-input" autocomplete="off" #searchTermMob name="search" placeholder="بحث" type="text" (keyup)="searchOnKeyUp($event)">
                                    <button class="search-button" type="button" routerLink="Search/{{searchTermMob.value!=''?searchTermMob.value:'  '}}">
                                        <i class="flaticon-loupe"></i>
                                    </button>
                                    
                                    <div  *ngIf="flag">   
                                        <ul class="list-group list-group-flush" style="    padding-right: 0;">  
                                            <li *ngFor="let sr of searchResult"  class="list-group-item ">  
                                                <a   [routerLink]="sr.pageUrl" >{{sr.title}}</a>  
                                            </li>             
                                        </ul> 
                                        <div style="text-align: center;padding-top: 5%;">
                                            
                                        <a style="cursor: pointer;"  routerLink="Search/{{searchTermMob.value}}">عرض الكل</a>  
                                        </div>
                                    </div> 
                                </form>
                            </div>
                        </div>
                         
        </div>
    </div>
</div>
<!-- End Navbar -->
