import { Injectable } from '@angular/core';
import {Title,Meta} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private title: Title, private meta: Meta,) { 
 
  }

  
  updateTitle(title: string) {
    this.title.setTitle(title);
  }
  updateTags(tags) {
    if(tags){

      if (tags.ogurl) {
        this.meta.updateTag({property: 'og:url',content:tags.ogurl})
      } else {
        this.meta.removeTag("property='og:url'")
      } 
      if (tags.ogtype) {
        this.meta.updateTag({property: 'og:type',content:tags.ogtype})
      } else {
        this.meta.removeTag("property='og:type'")
      }
      if (tags.ogtitle) {
        this.meta.updateTag({property : 'og:title',content:tags.ogtitle})
      } else {
        this.meta.removeTag("property='og:title'")
      }
      if (tags.ogdescription) {
        this.meta.updateTag({property: 'og:description',content:tags.ogdescription})
      } else {
        this.meta.removeTag("property='og:description'")
      }
      if (tags.ogimage) {
        this.meta.updateTag({property : 'og:image',content:tags.ogimage})
      } else {
        this.meta.removeTag("property='og:image'")
      }
      if (tags.ogimagetype) {
        this.meta.updateTag({property: 'og:image:type',content:tags.ogimagetype})
      } else {
        this.meta.removeTag("property='og:image:type'")
      }
      if (tags.ogimagewidth) {
        this.meta.updateTag({property: 'og:image:width',content:tags.ogimagewidth})
      } else {
        this.meta.removeTag("property='og:image:width'")
      }
      if (tags.ogimageheight) {
        this.meta.updateTag({property: 'og:image:height',content:tags.ogimageheight})
      } else {
        this.meta.removeTag("property='og:image:height'")
      }
      if (tags.ogsitename) {
        this.meta.updateTag({property: 'og:site_name',content:tags.ogsitename})
      } else {
        this.meta.removeTag("property='og:site_name'")
      }  if (tags.oglocale) {
        this.meta.updateTag({property: 'og:locale',content:tags.oglocale})
      } else {
        this.meta.removeTag("property='og:locale'")
      }
      if (tags.ogupdatedtime) { 
        this.meta.updateTag({ property:'og:updated_time', content: tags.ogupdatedtime})
      } else {
        this.meta.removeTag("property='og:updated_time'")
      }
          

  if (tags.description) {
    this.meta.updateTag({ name: 'description',content:tags.description })
  } else {
    this.meta.removeTag("name='description'")
  }

  if (tags.keywords) {
    this.meta.updateTag({ name: 'keywords',content:tags.keywords})
  } else {
    this.meta.removeTag("name='keywords'")
  }
  if (tags.articaltags) {
    tags.articaltags.split(',').forEach(tag=>{
      this.meta.updateTag({ name: 'artical:tag',content:tag})
    });
    } else {
    this.meta.removeTag("name='artical:tag'")
  }
  if (tags.image) {
    this.meta.updateTag({ name:'image',content:tags.image })
  } else {
    this.meta.removeTag("name='image'")
  }



  
  if (tags.twittercard) {
    this.meta.updateTag({ name: 'twitter:card', content: tags.twittercard})
  } else {
    this.meta.removeTag("name='twittercard'")
  }

  if (tags.twitterdescription) {
    this.meta.updateTag({ name: 'twitter:description', content: tags.twitterdescription})
  } else {
    this.meta.removeTag("name='twitter:description'")
  }

  if (tags.twittertitle) {
    this.meta.updateTag({ name: 'twitter:title', content:tags.twittertitle})
  } else {
    this.meta.removeTag("name='twitter:title'")
  }
  if (tags.twitterimagesrc) {
    this.meta.updateTag({ name: 'twitter:image:src',content:tags.twitterimagesrc})
  } else {
    this.meta.removeTag("name='twitter:image:src'")
  }
  if (tags.applemobilewebappcapable) {
    this.meta.updateTag({ name: 'apple-mobile-web-app-capable', content:tags.applemobilewebappcapable})
  } else {
    this.meta.removeTag("name='apple-mobile-web-app-capable'")
  } if (tags.mobilewebappcapable) {
    this.meta.updateTag({ name: 'mobile-web-app-capable', content: tags.mobilewebappcapable})
  } else {
    this.meta.removeTag("name='mobile-web-app-capable'")
  } if (tags.rating) {
    this.meta.updateTag({ name: 'rating',content:tags.rating})
  } else {
    this.meta.removeTag("name='rating'")
  }
  if (tags.itempropname) {
    this.meta.removeTag("itemprop='name'")

    this.meta.updateTag({ itemprop: 'name', content:tags.itempropname})
  } else {
    this.meta.removeTag("itemprop='name'")
  }
  if (tags.itempropdescription) {
    this.meta.removeTag("itemprop='description'")
    this.meta.updateTag({ itemprop: 'description', content:tags.itempropdescription})
  } else {
    this.meta.removeTag("itemprop='description'")
  }
  if (tags.itempropimage) {
    this.meta.removeTag("itemprop='image'")

    this.meta.updateTag({ itemprop:'image', content: tags.itempropimage})
  } else {
    this.meta.removeTag("itemprop='image'")
  }
  
  if (tags.articlepublisher) { 
    this.meta.updateTag({ name:'article:publisher', content: tags.articlepublisher})
  } else {
    this.meta.removeTag("name='article:publisher'")
  }

  if (tags.articlesection) { 

    this.meta.updateTag({ name:'article:section', content: tags.articlesection})
  } else {
    this.meta.removeTag("name='article:section'")
  }

  if (tags.articleauthor) { 
    this.meta.updateTag({ name:'article:author', content: tags.articleauthor})
  } else {
    this.meta.removeTag("name='article:author'")
  }

  if (tags.bookauthor) { 
    this.meta.updateTag({ name:'book:author', content: tags.bookauthor})
  } else {
    this.meta.removeTag("name='book:author'")
  }
  

  }
}
  updateTagDirect(tagNames,tagContents) {
    for (let index = 0; index < tagNames.length; index++) {
      this.meta.updateTag({ name: tagNames[index], content: tagContents[index] });
      
    } 
    
  }
    updateDescription(desc) { 
      
    this.meta.updateTag({ name: 'description', content:desc});
    }

    updateKeywords(keys) { 
      
      this.meta.updateTag({ name: 'keywords', content:keys});
      }
       stripHtml(html)
      {
         let tmp = document.createElement("DIV");
         tmp.innerHTML = html;
         return tmp.textContent.replace('&nbsp;', "") || tmp.innerText.replace('&nbsp;', "")  || "";
      }
   
}

