import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArabicNumberPipePipe } from 'src/app/pipe/arabic-number-pipe.pipe';
 import { ShortenTextPipe } from 'src/app/pipe/shorten-text.pipe'; 
 import { ErrorComponent } from 'src/app/components/common/error/error.component';
import { PreloaderComponent } from 'src/app/components/common/preloader/preloader.component';
import { LazyImgDirective } from 'src/app/Directives/lazy-img.directive';
 


@NgModule({
  declarations: [
    ArabicNumberPipePipe,
     ShortenTextPipe,
     ErrorComponent,
    PreloaderComponent, 
    LazyImgDirective, 
  ],
  imports: [
    CommonModule,
    ],
  exports:[
    ArabicNumberPipePipe,
     ShortenTextPipe,
     ErrorComponent,
    PreloaderComponent, 
    LazyImgDirective, 
  ]
})
export class SharedInAllComponentsModule { }
